import { Button } from "react-native-paper";

import { Text, View } from "../../components/shared/Themed";
import { Controller, useForm } from "react-hook-form";
import { TextInputControl } from "../../components/custom/TextInputControl";
import { ReadOnlyProps } from "../../utils/templates";
import appColors from "../../utils/constants/colors";
import { resetUserPassword } from "../../services/usersService";
import { useRoute } from "@react-navigation/native";
import { useContext, useState } from "react";
import {
  StyleSheet,
  Image,
  useWindowDimensions,
  ImageBackground,
} from "react-native";

import { useToast } from "react-native-toast-notifications";
import DeviceOrientationContext from "../../components/context/DeviceOrientationContext";
import { Card } from "react-native-paper";

interface Props {
  navigation: any;
}

export const ResetPasswordScreen = ({ navigation }: ReadOnlyProps<Props>) => {
  const toast = useToast();
  const [isLoading, setLoading] = useState<boolean>(false);
  const route = useRoute();
  const { token } = route.params || {};
  const dimensions = useWindowDimensions();

  const { isSmallDevice } = useContext(DeviceOrientationContext);
  const forgetPasswordDefaultValues = {
    userPassword: "",
    userConfirmPassword: "",
  };

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: forgetPasswordDefaultValues,
  });

  const onSubmit = async (formData: any) => {
    setLoading(true);
    await resetUserPassword(formData, token)
      .then(() => {
        toast.show("Password updated successfully", {
          type: "success",
        });
        navigation.navigate("Login", { screen: "login" });
      })
      .catch((err: any) => {
        toast.show(err || "An error occurred while resetting the password", {
          type: "danger",
        });
      });
  };

  return (
    <>
      <View style={styles.container}>
        <View testID="loginPanel" style={{ flexDirection: "row" }}>
          <View
            testID="loginLeftPanel"
            style={{
              width: dimensions.width / 2,
              backgroundColor: "#ededed",
              display: isSmallDevice ? "none" : "flex",
            }}
          >
            <Image
              source={{ uri: require("../../assets/AnalyticsLogo.png") }}
              style={{
                width: "100%",
                height: dimensions.height / 2,
                marginTop: dimensions.height / 3.5,
              }}
            />
          </View>
          <View
            testID="loginRightPanel"
            style={[
              {
                width: isSmallDevice ? dimensions.width : dimensions.width / 2,
              },
            ]}
          >
            <ImageBackground
              source={{
                uri: isSmallDevice
                  ? require("../../assets/AnalyticsLogo.png")
                  : "",
              }}
              style={[styles.bgImage]}
              resizeMode="cover"
            >
              <View
                style={{
                  width: isSmallDevice ? "80%" : "50%",
                  marginTop: dimensions.height / 8,
                  marginLeft: isSmallDevice ? "10%" : "27%",
                  backgroundColor: "transparent",
                }}
              >
                <Image
                  style={styles.tinyLogo}
                  source={require("../../assets/logo2.svg")}
                />
                <Card testID="loginMultiSteps" mode="elevated">
                  <Card.Content style={[styles.content]}>
                    <View style={[styles.contentLabel]}>
                      <Text style={[styles.boldFont, { fontSize: 20 }]}>
                        Reset Password
                      </Text>
                    </View>
                    <View style={[styles.px_5]}>
                      <Controller
                        control={control}
                        rules={{
                          validate: {
                            required: async (value) => {
                              if (!value) return "* This is required.";
                            },
                          },
                        }}
                        name="userPassword"
                        render={({ field: { onChange, value } }) => (
                          <TextInputControl
                            title="Password"
                            required={true}
                            width="100%"
                            placeholder="Enter Password"
                            value={value}
                            setChangeText={onChange}
                            blurOnSubmit={false}
                            handleOnSubmitEditing={handleSubmit(onSubmit)}
                          />
                        )}
                      />
                      {errors.userPassword && (
                        <Text style={styles.errorColor}>
                          {errors.userPassword.message}.
                        </Text>
                      )}
                    </View>
                    <View style={[styles.px_5]}>
                      <Controller
                        control={control}
                        rules={{
                          validate: {
                            required: async (value) => {
                              if (!value) return "* This is required.";
                            },
                          },
                        }}
                        name="userConfirmPassword"
                        render={({ field: { onChange, value } }) => (
                          <TextInputControl
                            title="Confirm Password"
                            required={true}
                            width="100%"
                            placeholder="Enter Confirm Password"
                            value={value}
                            setChangeText={onChange}
                            blurOnSubmit={false}
                            handleOnSubmitEditing={handleSubmit(onSubmit)}
                          />
                        )}
                      />
                      {errors.userConfirmPassword && (
                        <Text style={styles.errorColor}>
                          {errors.userConfirmPassword.message}.
                        </Text>
                      )}
                    </View>
                    <View style={[styles.px_5]}>
                      <Button
                        mode="contained"
                        onPress={handleSubmit(onSubmit)}
                        loading={isLoading}
                        color={appColors.multiSteps.background}
                        style={{ marginTop: 15 }}
                        uppercase={false}
                      >
                        Reset Password
                      </Button>
                    </View>
                  </Card.Content>
                </Card>
              </View>
            </ImageBackground>
          </View>
        </View>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  contentLabel: {
    justifyContent: "center",
    alignContent: "center",
    flexDirection: "row",
    paddingTop: "5%",
    paddingBottom: "5%",
  },
  errorColor: {
    color: "red",
  },
  px_5: { paddingLeft: "5%", paddingRight: "5%" },
  btnBackToLogin: {
    marginTop: "2%",
    color: "#adacac",
    textDecorationLine: "underline",
    justifyContent: "center",
    textAlign: "center",
  },
  boldFont: {
    fontWeight: "bold",
  },
  container: {
    flex: 1,
    flexDirection: "row",
  },
  tinyLogo: {
    width: 250,
    height: 60,
    alignSelf: "center",
    marginBottom: "5%",
  },
  content: {
    padding: 0,
    borderColor: "#eee",
    borderWidth: 1,
    borderStyle: "solid",
  },

  bgImage: {
    flex: 1,
    justifyContent: "center",
  },

  successColor: {
    color: "#037d50",
  },
  forgetPasswordLink: {
    marginTop: "2%",
    fontStyle: "italic",
    color: "#adacac",
    textDecorationLine: "underline",
  },
});
