import React, { memo, useCallback, useEffect, useState } from "react";
import {
  View,
  Button,
  TouchableOpacity,
  StyleSheet,
  Modal,
  Dimensions,
  Alert,
  Platform,
  Image,
} from "react-native";
import * as DocumentPicker from "expo-document-picker";
import { getFileFromS3, pushFileToS3 } from "../../../services/s3BucketService";
import {
  addDeliveryAttachment,
  getDeliveryAttachment,
  updateDeliveryAttachment,
} from "../../../services/deliveryService";
import FilePreview from "../../Operations/utils/FilePreview";
import { ReadOnlyProps } from "../../../utils/templates";
import { BlurView } from "expo-blur";
import { IconButton, Text } from "react-native-paper";
import Spinner from "../../../components/shared/Spinner";
import { useToast } from "react-native-toast-notifications";
import * as ImagePicker from "expo-image-picker";
import { TextInput } from "react-native-gesture-handler";
import Icon from "react-native-vector-icons/Ionicons";
import { Ionicons } from "@expo/vector-icons";

interface UploadAttachmentDeliveryContentProps {
  selectedItem: any;
  setModalVisible: (visible: boolean) => void;
}

const UploadAttachmentDeliveryContent: React.FC<UploadAttachmentDeliveryContentProps> =
  memo(({ selectedItem, setModalVisible }) => {
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const [isUploaded, setIsUploaded] = useState<boolean>(false);
    const [deliveryAttachment, setDeliveryAttachment] = useState<any>(null);
    const [file, setFile] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const toast = useToast();
    const [docNum] = useState(selectedItem?.DocNum);
    const [remarks, setRemarks] = useState<string>("");

    // const [image, setImage] = useState<string>(profilePhoto);
    // const [imageBase64, setImageBase64] = useState<string>("");

    // const pickImage = async () => {
    //   // No permissions request is necessary for launching the image library
    //   let result = await ImagePicker.launchImageLibraryAsync({
    //     mediaTypes: ImagePicker.MediaTypeOptions.All,
    //     allowsEditing: true,
    //     aspect: [1, 1],
    //     quality: 0.3,
    //     base64: true,
    //     exif: false,
    //   });

    //   if (!result.cancelled) {
    //     setImageBase64(result.base64 as string);
    //     setImage(result.uri);
    //   }
    // };

    useEffect(() => {
      let isMounted = true;

      const fetchData = async () => {
        try {
          setLoading(true);
          const response = await getDeliveryAttachment(docNum);
          if (response?.data?.status) {
            setDeliveryAttachment(response.data.data);
            const attachment = response.data.data.attachment;
            if (attachment) {
              const fileData = await getFileFromS3(attachment, "delivery");
              if (fileData?.imageUrl) {
                setFile({
                  fileName: attachment,
                  fileUri: fileData.imageUrl,
                  fileType: attachment.substring(
                    attachment.lastIndexOf(".") + 1
                  ),
                });
              }
              if (response.data.data?.remarks) {
                setRemarks(response.data.data.remarks);
              }
            }
          }
        } catch (error) {
          console.error("Error fetching delivery attachment:", error);
        } finally {
          setLoading(false);
        }
      };

      if (isMounted && docNum) {
        fetchData();
      }

      return () => {
        isMounted = false;
      };
    }, [docNum]);

    const handleSubmit = useCallback(async () => {
      try {
        if (file) {
          setLoading(true);
          let uniqueFileName = "";
          if (
            deliveryAttachment &&
            file &&
            file.fileName != deliveryAttachment.attachment
          ) {
            // update with new file scenario
            setIsUploading(true);
            const uniqueId = new Date().getTime();
            uniqueFileName = uniqueId + file.fileName;
            const s3UploadResponse = await pushFileToS3(
              "POST",
              file.fileUri,
              uniqueFileName,
              file.fileType,
              "delivery"
            );
            if (s3UploadResponse) {
              setIsUploading(false);
            }
          } else if (!deliveryAttachment) {
            // new file scenario for addition
            setIsUploading(true);
            const uniqueId = new Date().getTime();
            uniqueFileName = uniqueId + file.fileName;
            const s3UploadResponse = await pushFileToS3(
              "POST",
              file.fileUri,
              uniqueFileName,
              file.fileType,
              "delivery"
            );
            if (s3UploadResponse) {
              setIsUploading(false);
            }
          } else if (
            deliveryAttachment &&
            file &&
            file.fileName == deliveryAttachment.attachment
          ) {
            uniqueFileName = deliveryAttachment.attachment;
          }

          if (!deliveryAttachment) {
            await addDeliveryAttachment(
              selectedItem?.DocNum,
              uniqueFileName,
              remarks
            );
          } else {
            await updateDeliveryAttachment(
              selectedItem?.DocNum,
              uniqueFileName,
              remarks
            );
          }
        }

        toast.show("Submission Successful", { type: "success" });
        setModalVisible(false);
      } catch (error) {
        console.error("Error submitting data: ", error);
        toast.show("Submission Failed", { type: "danger" });
      } finally {
        setIsUploading(false);
        setLoading(false);
      }
    }, [
      file,
      deliveryAttachment,
      selectedItem,
      remarks,
      setModalVisible,
      toast,
    ]);

    /* NEWER METHOD - WITH CAPTURE - WEB + NATIVE APPS */

    const selectImage = async () => {
      // No permissions request is necessary for launching the image library
      let result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.All,
        allowsEditing: true,
        aspect: [1, 1],
        quality: 0.3,
        base64: true,
        exif: false,
      });

      if (!result.canceled) {
        const fileObj = result.assets[0];
        await uploadImage(
          fileObj.fileName,
          fileObj.uri as string,
          fileObj.mimeType
        );
      }
    };

    const uploadImage = async (name: any, uri: string, mimeType: any) => {
      try {
        setFile({ fileName: name, fileUri: uri, fileType: mimeType });
      } catch (error) {
        console.error("Error fetching file: ", error);
        toast.show("Fetching File Failed", { type: "danger" });
      }
    };

    return (
      <>
        {loading ? (
          <Spinner size="small" color="red" styles={{ alignSelf: "center" }} />
        ) : (
          <View style={styles.container}>
            <Text style={styles.title}>Upload</Text>
            {file && (
              <TouchableOpacity
                onPress={selectImage}
                style={styles.selectButton}
              >
                <Button
                  title={isUploading ? "Uploading..." : "Update Image"}
                  onPress={selectImage}
                />
              </TouchableOpacity>
            )}
            {file ? (
              <View>
                <FilePreview
                  key={1}
                  fileName={file.fileName}
                  fileUri={file.fileUri}
                  fileType={file.fileType}
                  styles={styles}
                  mode="preview"
                  hideIcons={true}
                  showName={false}
                />
              </View>
            ) : (
              <TouchableOpacity onPress={selectImage} style={styles.uploadBox}>
                <Ionicons name="camera-outline" size={50} color="black" />
                <Text style={styles.uploadText}>Take photo</Text>
              </TouchableOpacity>
            )}
            <TextInput
              style={styles.input}
              placeholder="Enter remarks"
              value={remarks}
              onChangeText={setRemarks}
            />
            <TouchableOpacity
              style={styles.submitButton}
              onPress={handleSubmit}
            >
              <Text style={styles.submitText}>Submit</Text>
            </TouchableOpacity>
          </View>
        )}
      </>
    );
  });

const ModalContainer: React.FC<ReadOnlyProps<Props>> = memo(
  ({ childern, modalVisible, setModalVisible }) => {
    const Children = childern;

    return (
      <View>
        <Modal
          animationType="slide"
          transparent={true}
          visible={modalVisible}
          onRequestClose={() => setModalVisible(!modalVisible)}
        >
          <BlurView intensity={5} style={styles.centeredView}>
            <View
              style={[styles.modalView, { minWidth: "80%", minHeight: "80%" }]}
            >
              {Children && <Children />}
              <IconButton
                style={styles.button}
                icon="close"
                color={"#2196F3"}
                size={20}
                onPress={() => setModalVisible(!modalVisible)}
              />
            </View>
          </BlurView>
        </Modal>
      </View>
    );
  }
);

interface UploadAttachmentDeliveryModalProps {
  selectedItem: any;
  deliveryAttachmentModalVisible: boolean;
  setDeliveryAttachmentModalVisible: (visible: boolean) => void;
}

export const UploadAttachmentDeliveryModal: React.FC<UploadAttachmentDeliveryModalProps> =
  memo(
    ({
      selectedItem,
      deliveryAttachmentModalVisible,
      setDeliveryAttachmentModalVisible,
    }) => {
      return (
        <ModalContainer
          modalVisible={deliveryAttachmentModalVisible}
          setModalVisible={setDeliveryAttachmentModalVisible}
          childern={() => (
            <UploadAttachmentDeliveryContent
              selectedItem={selectedItem}
              setModalVisible={setDeliveryAttachmentModalVisible}
            />
          )}
        />
      );
    }
  );

interface Props {
  childern: React.ElementType;
  modalVisible?: boolean;
  isMobileDevice?: boolean;
  setModalVisible: (event: any) => void;
}

const { width } = Dimensions.get("window");

// Styles
const styles = StyleSheet.create({
  modalContent: {
    backgroundColor: "white",
    padding: 20,
    width: "80%",
    alignItems: "center",
    elevation: 5,
  },
  uploadIconContainer: {
    width: 690,
    height: 233,
    borderRadius: 10,
    borderWidth: 1,
    backgroundColor: "#D4EDFF",
  },
  title: {
    fontSize: 37,
    marginBottom: 20,
    fontWeight: "bold",
    color: "#000",
  },

  noImageText: {
    marginBottom: 20,
    color: "#777",
  },
  selectButton: {
    marginBottom: 20,
  },
  buttonsContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    marginTop: 20,
  },
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  modalView: {
    backgroundColor: "white",
    borderRadius: 2,
    alignItems: "center",
    justifyContent: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
  },
  button: {
    marginTop: 10,
    minWidth: "1%",
    borderRadius: 20,
    padding: 10,
    elevation: 2,
  },
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "white",
    padding: 20,
  },
  uploadBox: {
    width: width < 500 ? 229 : 690, // Adjust based on screen width
    height: width < 500 ? 163 : 233,
    backgroundColor: "#D7EDFF",
    borderRadius: 10,
    borderWidth: 1,
    borderColor: "gray",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 30,
    marginTop: 30,
  },
  uploadText: {
    marginTop: 10,
    fontSize: 16,
  },
  input: {
    width: "100%",
    height: 40,
    borderWidth: 1,
    borderColor: "gray",
    borderRadius: 10,
    paddingHorizontal: 10,
    marginTop: 30,
    marginBottom: 20,
  },
  submitButton: {
    width: "100%",
    backgroundColor: "#8DC7FF",
    paddingVertical: 10,
    alignItems: "center",
    borderRadius: 10,
    marginTop: 20,
  },
  submitText: {
    fontSize: 16,
    fontWeight: "bold",
    color: "black",
  },
  image: {
    width: "100%",
    height: "100%",
    borderRadius: 10,
  },
  imagePreview: {
    width: width < 500 ? 229 : 690, // Adjust based on screen width
    height: width < 500 ? 163 : 233,
    borderWidth: 1,
    // maxWidth: 400,
    // maxHeight: 400,
    marginBottom: 20,
    borderRadius: 10,
    justifyContent: "center",
    alignItems: "center",
    objectFit: "contain",
  },
});
