import { useState } from "react";
import { TouchableOpacity, StyleSheet } from "react-native";
import { Button } from "react-native-paper";

import { Text, View } from "../../components/shared/Themed";
import * as Services from "../../utils/common";
import { Controller, useForm } from "react-hook-form";
import { TextInputControl } from "../../components/custom/TextInputControl";
import { ReadOnlyProps } from "../../utils/templates";
import appColors from "../../utils/constants/colors";
import { forgotUserPassword } from "../../services/usersService";
import { useToast } from "react-native-toast-notifications";
import { useNavigation } from "@react-navigation/native";

interface Props {
  setForgetPasswordStatus: (e: boolean) => void;
  setDisplayForgetPasswordScreen: (e: boolean) => void;
}

export const ForgotPasswordScreen = ({
  setForgetPasswordStatus,
  setDisplayForgetPasswordScreen,
}: ReadOnlyProps<Props>) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const forgetPasswordDefaultValues = {
    userEmail: "",
  };
  const navigation = useNavigation();
  const toast = useToast();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: forgetPasswordDefaultValues,
  });

  const onSubmit = async (formData: any) => {
    setLoading(true);
    await forgotUserPassword(formData.userEmail)
      .then(() => {
        setLoading(false);
        toast.show("Password reset email sent", {
          type: "success",
        });
        navigation.navigate("Login");
      })
      .catch((err: any) => {
        setLoading(false);
        toast.show(err || "An error occurred while resetting the password", {
          type: "danger",
        });
      });
  };

  return (
    <>
      <View style={[styles.contentLabel]}>
        <Text style={[styles.boldFont, { fontSize: 20 }]}>Reset Password</Text>
      </View>
      <View style={[styles.px_5]}>
        <Controller
          control={control}
          rules={{
            validate: {
              required: async (value) => {
                if (!value) return "* This is required.";
                if (!Services.validateEmail(value)) {
                  return "* Invalid email format";
                }
              },
            },
          }}
          name="userEmail"
          render={({ field: { onChange, value } }) => (
            <TextInputControl
              title="Email"
              required={true}
              width="100%"
              placeholder="Enter Email"
              value={value}
              setChangeText={onChange}
              blurOnSubmit={false}
              handleOnSubmitEditing={handleSubmit(onSubmit)}
            />
          )}
        />
        {errors.userEmail && (
          <Text style={styles.errorColor}>{errors.userEmail.message}.</Text>
        )}
      </View>
      <View style={[styles.px_5]}>
        <Button
          mode="contained"
          onPress={handleSubmit(onSubmit)}
          loading={isLoading}
          color={appColors.multiSteps.background}
          style={{ marginTop: 15 }}
          uppercase={false}
        >
          Reset Password
        </Button>
        <TouchableOpacity onPress={() => setDisplayForgetPasswordScreen(false)}>
          <Text style={[styles.btnBackToLogin]}>Back to Login</Text>
        </TouchableOpacity>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  contentLabel: {
    justifyContent: "center",
    alignContent: "center",
    flexDirection: "row",
    paddingTop: "5%",
    paddingBottom: "5%",
  },
  errorColor: {
    color: "red",
  },
  px_5: { paddingLeft: "5%", paddingRight: "5%" },
  btnBackToLogin: {
    marginTop: "2%",
    color: "#adacac",
    textDecorationLine: "underline",
    justifyContent: "center",
    textAlign: "center",
  },
  boldFont: {
    fontWeight: "bold",
  },
});
