import mapiConfig from "../configs/mapi";

import { AuthSdkError, UserClaims } from "@okta/okta-auth-js";
import { authClient } from "./oktaAuthService";
import {
  oktaBypassUserClaimInformation,
  User,
  userClaimInformation,
} from "../models/usersModel";
import { httpMStepsClient } from "./utils/httpService";
import { logError } from "./utils/logService";
import { accessToken, idToken } from "./utils/tokenService";
import {
  getCompanyDetailsByUserId,
  getCompanyDetailsByUserIdV2,
} from "./companyService";

import * as Sentry from "@sentry/browser";

//BASE_URL
const BASE_URL = mapiConfig.api.domain;
const Controller = "/users";

export const getUserInfo = async (): Promise<UserClaims> => {
  try {
    const _accessToken = await accessToken();
    const _idToken = await idToken();

    if (!_idToken) {
      try {
        // Fallback to custom user info fetching if the first method fails
        const result = await httpMStepsClient({
          baseURL: BASE_URL,
          type: "GET",
          path: "users/userinfo",
        });
        return (await result.data) as UserClaims;
      } catch (error) {
        // Log and capture the error from the fallback as well
        logError(error);
        Sentry.captureException(error);
        throw new Error("Failed to retrieve user info from both sources.");
      }
    }
    // Attempt to fetch user info using the auth client
    const userInfo = await authClient.token.getUserInfo(_accessToken, _idToken);
    return userInfo as UserClaims;
  } catch (error) {
    // Log and capture the exception
    logError(error);
    Sentry.captureException(error);
    throw new Error("Error");
  }
};

export const getUsers = async (listAll: boolean) => {
  const userInfo = await getUserInfo();
  const userId = !listAll ? userInfo.sub : "all";

  const methodPath = `${Controller}/search/${userId}`;

  return await httpMStepsClient({
    baseURL: BASE_URL,
    type: "GET",
    path: methodPath,
  })
    .then((result) => {
      return {
        users: result.data,
        isLoading: false,
      };
    })
    .catch((error) => {
      logError(error);
      Sentry.captureException(error);

      return {
        users: [],
        isLoading: false,
      };
    });
};

export const getUsersByCompanyId = async (companyId: string) => {
  const methodPath = `${Controller}/search/company/${companyId}`;

  return await httpMStepsClient({
    baseURL: BASE_URL,
    type: "GET",
    path: methodPath,
  })
    .then((result) => {
      return {
        users: result.data,
        isLoading: false,
      };
    })
    .catch((error) => {
      logError(error);
      Sentry.captureException(error);

      return {
        users: [],
        isLoading: false,
      };
    });
};

export const getOktaBypassUsers = async () => {
  const methodPath = `${Controller}/bypass-users`;

  return await httpMStepsClient({
    baseURL: BASE_URL,
    type: "GET",
    path: methodPath,
  })
    .then((result) => {
      return {
        users: result.data,
        isLoading: false,
      };
    })
    .catch((error) => {
      logError(error);
      Sentry.captureException(error);

      return {
        users: [],
        isLoading: false,
      };
    });
};

export const addUser = async () => {
  const methodPath = `${Controller}/add`;
  const userInfo = await getUserInfo();
  const getCompany = await getCompanyDetailsByUserIdV2();

  const companyIds = getCompany?.company?.data?.map(
    (company: any) => company.company_id
  );

  const httpBody: userClaimInformation = {
    login: userInfo.login,
    email: userInfo.primaryEmail as string,
    auto_sync: (userInfo.primaryEmail as string).includes("multisteps")
      ? true
      : false,
    team: userInfo.team,
    company_ids: companyIds,
    first_name: userInfo.given_name,
    last_name: userInfo.family_name,
    display_name: `${userInfo.given_name} ${userInfo.family_name}`,
    user_id: userInfo.sub,
    zone_info: userInfo.zoneinfo,
  };

  return await httpMStepsClient({
    baseURL: BASE_URL,
    type: "POST",
    path: methodPath,
    body: httpBody,
  })
    .then(() => {
      return {
        status: "ACTIVE",
        users: httpBody,
        isLoading: false,
      };
    })
    .catch((error) => {
      logError(error);
      Sentry.captureException(error);
    });
};

export const addOktaBypassUser = async (requestBody: any) => {
  const methodPath = `${Controller}/create-okta-bypass-user`;
  const companyIds = [];
  companyIds.push(requestBody.companyId);
  const httpBody: oktaBypassUserClaimInformation = {
    login: requestBody.login,
    email: requestBody.email as string,
    auto_sync: false,
    company_ids: companyIds,
    first_name: requestBody.firstName,
    last_name: requestBody.lastName,
    display_name: requestBody.displayName,
    zone_info: requestBody.zone,
    team: requestBody.team,
    password: requestBody.password,
    role: requestBody.role,
  };

  return await httpMStepsClient({
    baseURL: BASE_URL,
    type: "POST",
    path: methodPath,
    body: httpBody,
  })
    .then((result) => {
      return {
        data: result.data,
        isSuccessfull: true,
        isLoading: false,
      };
    })
    .catch((error) => {
      logError(error);
      Sentry.captureException(error);

      return {
        isSuccessfull: false,
        isLoading: false,
        data: error,
      };
    });
};

export const updateUser = async (httpBody: User, selectedUserId: string) => {
  const userInfo = await getUserInfo();
  const userId: String = selectedUserId !== "" ? selectedUserId : userInfo.sub;
  const methodPath = `${Controller}/update/${userId}`;

  const httpBodyParam = Object.keys(httpBody).length
    ? httpBody
    : {
        login: userInfo.login,
        email: userInfo.primaryEmail,
        team: userInfo.team,
        zone_info: userInfo.zoneinfo,
        first_name: userInfo.given_name,
        last_name: userInfo.family_name,
        display_name: `${userInfo.given_name} ${userInfo.family_name}`,
      };

  return await httpMStepsClient({
    baseURL: BASE_URL,
    type: "PUT",
    path: methodPath,
    body: httpBodyParam,
  })
    .then(() => {
      return {
        status: "ACTIVE",
        isLoading: false,
      };
    })
    .catch((error) => {
      logError(error);
      Sentry.captureException(error);
    });
};

export const getRole = async (companyId: string) => {
  const userInfo = await getUserInfo();
  const userId: string = userInfo.sub;
  const methodPath = `${Controller}/search/${userId}`;

  return await httpMStepsClient({
    baseURL: BASE_URL,
    type: "GET",
    path: methodPath,
  })
    .then((result) => {
      if (result.data.length) {
        const currentRole = result.data[0].roles.filter((role: any) => {
          return role.company_id === companyId;
        });

        return {
          role: currentRole[0].role,
          isLoading: false,
        };
      }

      return {
        role: "MEMBER",
        isLoading: false,
      };
    })
    .catch((error) => {
      logError(error);
      Sentry.captureException(error);
    });
};

export const getBypassUserDetailsById = async (userId: string) => {
  const methodPath = `${Controller}/bypass-users/${userId}`;

  return await httpMStepsClient({
    baseURL: BASE_URL,
    type: "GET",
    path: methodPath,
  })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      logError(error);
      Sentry.captureException(error);
      return error;
    });
};

export const deleteBypassUserById = async (userId: string) => {
  const methodPath = `${Controller}/bypass-users/${userId}`;
  return await httpMStepsClient({
    baseURL: BASE_URL,
    type: "DELETE",
    path: methodPath,
  })
    .then((result) => {
      return {
        data: result.data,
        isSuccessfull: true,
        isLoading: false,
      };
    })
    .catch((error) => {
      logError(error);
      Sentry.captureException(error);

      return {
        isSuccessfull: false,
        isLoading: false,
      };
    });
};

export const updateOktaBypassUser = async (
  requestBody: any,
  userId: string
) => {
  const methodPath = `${Controller}/update-okta-bypass-user/${userId}`;
  const companyIds = [];
  companyIds.push(requestBody.companyId);
  const httpBody: oktaBypassUserClaimInformation = {
    login: requestBody.login,
    email: requestBody.email as string,
    auto_sync: false,
    company_ids: companyIds,
    first_name: requestBody.firstName,
    last_name: requestBody.lastName,
    display_name: requestBody.displayName,
    zone_info: requestBody.zone,
    team: requestBody.team,
    password: requestBody.password,
    role: requestBody.role,
  };

  return await httpMStepsClient({
    baseURL: BASE_URL,
    type: "PATCH",
    path: methodPath,
    body: httpBody,
  })
    .then((response) => {
      return {
        status: "ACTIVE",
        users: httpBody,
        isLoading: false,
        response: response,
      };
    })
    .catch((error) => {
      // Check if error is defined
      if (error) {
        logError(error);
        Sentry.captureException(error);
        return {
          status: "FAILED",
          isLoading: false,
          error: error,
        };
      } else {
        console.log("Caught error: Undefined error");
        logError("Undefined error caught");
        return {
          status: "FAILED",
          isLoading: false,
          error: "An unexpected error occurred",
        };
      }
    });
};

export const resetUserPassword = async (requestBody: any, token: any) => {
  const methodPath = `${Controller}/reset-password/${token}`;
  return await httpMStepsClient({
    baseURL: BASE_URL,
    type: "POST",
    path: methodPath,
    body: requestBody,
    isTokenRequired: false,
  });
};

export const forgotUserPassword = async (email: string) => {
  const methodPath = `${Controller}/forgot-password/${email}`;
  return await httpMStepsClient({
    baseURL: BASE_URL,
    type: "GET",
    path: methodPath,
    isTokenRequired: false,
  });
};
