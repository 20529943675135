/**
 * If you are not familiar with React Navigation, refer to the "Fundamentals" guide:
 * https://reactnavigation.org/docs/getting-started
 *
 */
import { FontAwesome } from "@expo/vector-icons";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { useState, useEffect, useContext } from "react";
import { Pressable, Dimensions } from "react-native";

import Colors from "../../../utils/constants/colors";
import useColorScheme from "../../../hooks/useColorScheme";
import ModalScreen from "../../../screens/ModalScreen";
import NotFoundScreen from "../../../screens/NotFoundScreen";
import LoginScreen from "../../../screens/Login";
import UserAcceptInviteScreen from "../../../screens/Settings/UserSettingScreens/UserAcceptInviteScreen";
import OrgSettingsTabNavigator from "../TabNavigator/OrgSettingsTabNavigator";
import UpdateArtworkScreen from "../../../screens/Artworks/UpdateFormScreen";
import RootTabNavigator from "./RootTabNavigator";
import { RootStackParamList, RootTabScreenProps } from "../../../types";
import TabBarIcon from "../../../components/core/TabBarIcon";
import DeviceOrientationContext from "../../../components/context/DeviceOrientationContext";
import CreateOktaBypassUserAccountForm from "../../../screens/Settings/CreateUserSettingScreens/CreateOktaBypassUserAccountForm";
import CreateOktaUserNavigate from "../../../screens/Settings/CreateUserSettingScreens/CreateOktaUserNavigate";
import UpdateOktaBypassUserAccountForm from "../../../screens/Settings/CreateUserSettingScreens/UpdateOktaBypassUserAccountForm";
import { ResetPasswordScreen } from "../../../screens/Login/ResetPasswordScreen";

const Stack = createNativeStackNavigator<RootStackParamList>();

export default function RootNavigator() {
  const colorScheme = useColorScheme();
  const { isSmallDevice } = useContext(DeviceOrientationContext);

  const ReturnHomeButton = (navigation: any) => {
    return (
      <Pressable
        onPress={() =>
          navigation.navigate("Root", {
            screen: "Home",
            params: {
              screen: "TabTwo",
            },
          })
        }
        style={({ pressed }) => ({
          opacity: pressed ? 0.5 : 1,
        })}
      >
        <FontAwesome
          name="home"
          size={25}
          color={Colors[colorScheme].text}
          style={{ marginRight: 15 }}
        />
      </Pressable>
    );
  };

  useEffect(() => {
    let isMounted = true;
    return () => {
      isMounted = false;
    };
  });

  return (
    <Stack.Navigator>
      <Stack.Screen
        name="Root"
        component={RootTabNavigator}
        options={{ headerShown: false, title: "Multisteps GO" }}
      />

      <Stack.Screen
        name="Login"
        component={LoginScreen}
        options={{ headerShown: false, title: "Multisteps GO | Login" }}
      />

      <Stack.Screen
        name="ResetPassword"
        component={ResetPasswordScreen}
        options={{
          headerShown: false,
          title: "Multisteps GO | Reset Password",
        }}
      />

      <Stack.Screen
        name="Invite"
        component={UserAcceptInviteScreen}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="UpdateArtwork"
        component={UpdateArtworkScreen}
        options={({ navigation }: RootTabScreenProps<"UpdateArtwork">) =>
          isSmallDevice
            ? {
                title: "Artwork",
                headerTitleAlign: "center",
                tabBarIcon: ({ color }: { color: string }) =>
                  isSmallDevice && (
                    <TabBarIcon name="briefcase" color={color} />
                  ),
                headerRight: () => ReturnHomeButton(navigation),
              }
            : { headerShown: false }
        }
      />
      <Stack.Screen
        name="OrgSettings"
        component={OrgSettingsTabNavigator}
        options={{ headerShown: false, title: "Multisteps GO" }}
      />
      <Stack.Screen
        name="CreateUser"
        component={CreateOktaBypassUserAccountForm}
        options={{ headerShown: false, title: "Multisteps GO" }}
      />
      <Stack.Screen
        name="UpdateUser"
        component={UpdateOktaBypassUserAccountForm}
        options={{ headerShown: false, title: "Multisteps GO" }}
      />

      <Stack.Screen
        name="BypassUserDetails"
        component={CreateOktaUserNavigate}
        options={{ headerShown: false, title: "Multisteps GO" }}
      />

      <Stack.Screen
        name="NotFound"
        component={NotFoundScreen}
        options={{ title: "Oops!" }}
      />

      <Stack.Group screenOptions={{ presentation: "modal" }}>
        <Stack.Screen
          name="Modal"
          component={ModalScreen}
          options={({ navigation }: RootTabScreenProps<"Modal">) => ({
            title: "Modal",
            // tabBarIcon: ({ color }) => <TabBarIcon name="code" color={color} />,
            headerRight: () => (
              <Pressable
                onPress={() =>
                  navigation.navigate("Root", {
                    screen: "Home",
                    params: {
                      screen: "TabTwo",
                    },
                  })
                }
                style={({ pressed }) => ({
                  opacity: pressed ? 0.5 : 1,
                })}
              >
                <FontAwesome
                  name="info-circle"
                  size={25}
                  color={Colors[colorScheme].text}
                  style={{ marginRight: 15 }}
                />
              </Pressable>
            ),
          })}
        />
      </Stack.Group>
    </Stack.Navigator>
  );
}
